.exposition-details-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 64px;
}

.exposition-details-container main {
  flex: 1;
  display: grid;
  grid-template-columns: 500px 1fr;
  gap: 16px;

  /* padding: 84px 16px 32px; */
  padding: 32px 256px 32px;
}

.map-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 32px;

  max-height: 1000px;

  display: flex;
  flex-direction: column;

  /* each component spans one column */
  grid-column: span 2;
}

.map-card span.map-title {
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 10px;
}

.map-card span.map-title h1 {
  color: #414141;
  font-weight: 600;
}

.map-card span.map-title span {
  border: 1px dashed #757575;
  border-radius: 5px;
}

.map-card span.map-title span button {
  background-color: #fff;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1360px) {
  .exposition-details-container main {
    padding: 16px 16px 32px;
  }

}

@media (max-width: 768px) {
    /** each component inside main need to be in one column */
    .exposition-details-container main {
      grid-template-columns: 100% 1fr;
    }    
}

/* @media (max-width: 425px) {
  .map-card {
    max-height: 400px;      
  }
} */