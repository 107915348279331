@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Roboto, sans-serif;
  background-color: #f0f0f5;
}

body::-webkit-scrollbar {
  display: none;
}

input,
button,
textarea {
  font: 400 18px Roboto, sans-serif;
}

button {
  cursor: pointer;
}

form input::placeholder {
  color: #b5b5b5;
}

.button-style {
  width: 100%;
  height: 60px;
  background-color: #414141;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  margin-top: 16px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 60px;
  transition: filter 0.2s;
}

.button-style:hover {
  filter: brightness(70%);
}

.back-link {
  margin-top: 40px;
  color: #414141;
  font-size: 16px;
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.2s;

  display: flex;
}

.back-link svg {
  margin-right: 8px;
}

.back-link:hover {
  opacity: 0.8;
  color: #414141;
}