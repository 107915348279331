.users-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-top: 64px;
}

.users-container .users-headers {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 240px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-inline: 20px;
}

.users-container .users-headers h1 {
    font-size: 30px;
    font-weight: 600;
    color: #414141;
    margin-right: 20px;
}

.users-container .users-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 240px;
    margin-right: 20px;
    padding: 20px;
    overflow-y: auto;
}

.users-container .users-content span.users-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.users-container .users-content span.users-item span.users-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.users-container .users-content span.users-item span.users-row h6.users-title {
    font-size: 14px;
    font-weight: 500;
    color: #494949;
    margin-right: 10px;
}

.users-container .users-content span.users-item span.users-row h6.users-body {
    font-size: 14px;
    font-weight: 400;
    color: #494949;
}

.users-container .users-content span.users-item span.users-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}


@media (max-width: 768px) {
    .users-container {
        margin-left: 0
    }

    .users-container .users-headers {
        margin-left: 0
    }
}