.exposition-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 32px;

  /* width: 500px; */

  position: relative;

  display: flex;
  flex-direction: column;

  /* each component spans one column */
  /* grid-column: span 1; */
}

.exposition-card h1 {
  color: #414141;
  font-weight: 600;
}

.exposition-card .exposition-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exposition-card .exposition-header .exposition-buttons {
  display: flex;
  gap: 16px;
}

.exposition-card .exposition-header .exposition-buttons button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.5s;
}

.exposition-card .exposition-header .exposition-buttons button:hover {
  opacity: 0.8;
}

.exposition-card img {
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.exposition-card h2.exposition-subtitle {
  margin-top: 64px;
  text-align: center;
}

.exposition-card span.url-copy {
  display: flex;
  justify-content: center;
  gap: 16px;

  margin-top: 32px;
}

.exposition-card span.url-copy>p {
  font-size: 18px;
  text-align: center;
}

.exposition-card span.url-copy>svg {
  cursor: pointer;
  transition: opacity 0.5s;
}

.exposition-card span.url-copy>svg:hover {
  opacity: 0.8;
}

.exposition-card span.location-info {
  display: flex;
  justify-content: center;
  gap: 8px;

  margin-top: 32px;

}

.exposition-card span.location-info>p {
  font-size: 20px;
}

.exposition-card footer {
  margin-top: 64px;
  text-align: center;
  color: #b5b5b5;
  font-weight: 600;
}

.exposition-card footer h3 {
  text-transform: uppercase;
}

/** media screen width **/

@media (max-width: 768px) {
  .exposition-card {
    grid-column: span 2;
    /* each component spans one column */
  }
}