.categories-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 32px;

  /* width: 500px; */

  display: flex;
  flex-direction: column;

  /* each component spans one column */
  /* grid-column: span 1; */
}

.categories-card span.categories-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.categories-card span.categories-title h1 {
  color: #414141;
  font-weight: 600;
}

.categories-card span.categories-title span {
  border: 1px dashed #757575;
  border-radius: 5px;
}

.categories-card span.categories-title span button {
  background-color: #fff;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.categories-card div.categories-div-table {
  margin-top: 32px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .categories-card {
    grid-column: span 2;
    /* each component spans one column */
  }
}