.points-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 32px;

  height: 500px;

  display: flex;
  flex-direction: column;

  /* each component spans one column */
  grid-column: span 2;
}

.points-card span.points-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.points-card span.points-title h1 {
  color: #414141;
  font-weight: 600;
}

.points-card span.points-title span {
  border: 1px dashed #757575;
  border-radius: 5px;
}

.points-card span.points-title span button {
  background-color: #fff;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .points-card {
    grid-column: span 2;
    /* each component spans one column */
  }
}