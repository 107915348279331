.edit-exibition-container {
  width: 100vw;
  height: 100vh;
  padding: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-exibition-container main.content {
  width: auto;
  padding: 64px;
  background-color: #fff;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  gap: 64px;
  align-items: center;
}

.edit-exibition-container main.content section img {
  width: 100%;
  max-width: 500px;
}

.edit-exibition-container main.content section div.div-text h1 {
  font-size: 32px;
  margin-bottom: 16px;
}

.edit-exibition-container main.content section div.div-text p {
  font-size: 18px;
  color: #757575;
}

.edit-exibition-container main.content section a {
  justify-content: flex-start;
  margin-top: 0;
}

.edit-exibition-container main.content form {
  width: 100%;
  max-width: 400px;
}


@media only screen and (max-width: 768px) {
  .edit-exibition-container main.content {
    flex-direction: column;
  }

  .edit-exibition-container main.content section img {
    display: none;
  }
}

@media only screen and (max-width: 512px) {
  .edit-exibition-container {
    padding: 16px 32px;
  }

  .edit-exibition-container main.content {
    padding: 32px;
  }
}