.home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 64px;
}

.home-container .home-headers {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 240px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-inline: 20px;
}

.home-container .home-headers h1 {
  font-size: 30px;
  font-weight: 600;
  color: #414141;
  margin-right: 20px;
}

.home-container .home-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 240px;
  margin-right: 20px;
  padding: 20px;
  overflow-y: auto;
}

.home-container .home-content span.home-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.home-container .home-content span.home-item span.home-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.home-container .home-content span.home-item span.home-row h6.home-title {
  font-size: 14px;
  font-weight: 500;
  color: #494949;
  margin-right: 10px;
}

.home-container .home-content span.home-item span.home-row h6.home-body {
  font-size: 14px;
  font-weight: 400;
  color: #494949;
}

.home-container .home-content span.home-item span.home-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 1024px) {
  .home-container .home-content span.home-item {
    gap: 24px;
  }

  .home-container .home-content span.home-item span.home-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .home-container .home-headers {
    margin-left: 20px;
  }

  .home-container .home-content {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 425px) {
  .home-container .home-headers {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
  }

  .home-container .home-headers h1 {
    margin-bottom: 20px;
  }

  .home-container .home-content span.home-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .home-container .home-content span.home-item span.home-actions {
    width: 100%;
  }

  .home-container .home-content span.home-item span.home-actions button {
    width: 100%;
  }
}