.register-container {
  width: 100vw;
  height: 100vh;
  padding: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.register-container main.content {
  width: auto;
  padding: 64px;
  background-color: #fff;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  gap: 64px;
  align-items: center;
}

.register-container main.content section img {
  width: 100%;
  max-width: 600px;
}

.register-container main.content form {
  width: 100%;
  max-width: 400px;
}

.register-container main.content form div.back-button {
  width: 100%;
  margin-top: 64px;
}

.register-container main.content form div.back-button a {
  display: flex;
  align-items: center;
}

/*----------------------------------------------------------------------------*/
@media only screen and (max-width: 1024px) {
  .register-container main.content {
    flex-direction: column;
    gap: 0;
  }

  .register-container main.content section {
    display: flex;
    justify-content: center;
  }

  .register-container main.content section img {
    /* display: none; */
    width: 100%;
    max-width: 300px;
  }

  .register-container main.content form {
    width: 100%;
  }
}

/*----------------------------------------------------------------------------*/
@media only screen and (max-width: 768px) {
  .register-container main.content {
    flex-direction: column;
  }
}

@media only screen and (max-width: 512px) {
  .register-container {
    padding: 16px 32px;
  }

  .register-container main.content {
    /* display: block; */
    padding: 0;
    margin-top: 0;
    margin-bottom: auto;
  }

  .register-container main.content form div.back-button {
    margin-bottom: 64px;
  }
}
